.wrapper {
    height: auto;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.svg {
    width: auto;
    display: block;
    fill: currentColor;
    font-family: inherit;
    line-height: 0;
    height: 100%;
}

/* @media (min-aspect-ratio: 9 / 16) {
    .wrapper {
        height: auto;
        width: 100%;
    }
} */