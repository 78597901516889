
.searchInput {
    display: flex;
    flex: 1 1 auto;
    height: 35px;
    align-items: center;
    justify-content: center;
    /* background-color: rgba(40, 50, 72, 0.03); */
    border-radius: 5px;
    margin-bottom: 4px;
    background-color: rgb(86,85,86);
    margin: 0 20px;
    margin-top: 22px;
    margin-bottom: 10px;
}

.search {
    width: 14px;
    height: 14px;
    margin: 0 12px;
}

.input {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    height: 20px;
    margin: 0;
    border: none;
    line-height: 20px;
    outline: none;
    font-size: 14px;
    border-radius: 0 30px 30px 0;
    background-color: transparent;
    color: #cccccc;
    padding-left: 10px;
    &::input-placeholder {
        color: #cccccc;
    }
}

.clear {
    height: 17px;
    width: 17px;
    margin-right: 9px;
}