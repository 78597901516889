.video {
    position: relative;
    border-radius: 16px;
    background-color: #333;
}

.play {
    position: absolute;
    width: 60px;
    height: 60px;
    left: 50%;
    top: 50%;
    margin-left: -30px;
    margin-top: -30px;
}

.stop {
    position: absolute;
    width: 100%;
    height: 80%;
    top: 0;
    left: 0;
    z-index: 99;
}

/* *::-webkit-media-controls-panel {
    display: none!important;
    -webkit-appearance: none;
  }
  
  *::--webkit-media-controls-play-button {
    display: none!important;
    -webkit-appearance: none;
  }
  
  
  *::-webkit-media-controls-start-playback-button {
    display: none!important;
    -webkit-appearance: none;
  } */