
.result {
   width: 100%;
   padding-bottom: 100px;
   margin-top: 20px;
}

.title {
    margin: 18px 0 4px 0;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid rgb(103,105,104);
}

.list {
    height: calc(58vh - 80px - 130px);
    overflow-y: scroll;
    margin-bottom: 10px;
    padding: 0 16px;
}

.song {
    width: 100%;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    margin-top: 10px;
}

.left {
    margin-left: 10px;
    width: 50px;
    margin-right: 10px;
}
.right {
    flex: 1;
    padding-right: 20px;
    margin-right: auto;
    color: #fff;
}
.des {
    font-size: 16px;
    width: 100%;
}

.time {
    font-size: 13px;
    color: rgba(255, 255, 255, 0.6);
}

.selected {
    position: absolute;
    right: 20px;
}

.leftActive {
    margin-right: 10px;
    width: 50px;
    border: 1px solid #ff3141;
    border-radius: 6px;
    margin-left: 10px;
    width: 50px;
    margin-right: 10px;
}

.desActive {
    color: #ff3141;
    font-size: 16px;
    width: 100%;
}

.timeActive {
    font-size: 13px;
    color: #ff3141;
}