.container {
    width: 100vw;
    height: 100vh;
    position: relative;
    background-color: #242424;
}

.cover {
    background-color: #242424;
    background-image: url('https://s3-hk-music-video.s3.ap-east-1.amazonaws.com/pics/main.png');
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: relative;
}

.wrapper {
    position: absolute;
    bottom: 0;
    /* width: calc(100% - 40px); */
    width: 100%;
    /* left: 50%;
    margin-left: calc(20px - 50%); */
    color: #fff;
    /* padding: 0 20px; */
    box-sizing: border-box;
}

.inner {
    padding-bottom: 40px;
    background: linear-gradient(180deg, transparent, rgba(0, 0, 0, .4) 40%);
}

.title {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: left;
    padding: 0 20px;
    box-sizing: border-box;
}

.tips {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 50px;
    padding: 0 20px;
    box-sizing: border-box;
}

.tip {
    line-height: 34px;
    text-align: left;
}

.btn {
    padding: 0 20px;
    width: 100%;
    box-sizing: border-box;
}
.select {
    background-color: #ff3141;
    border-color: #ff3141;
}
.confirmWrapper {
    margin: 0 20px;
}
.confirm {
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
    background-color: #ff3141;
    border-color: #ff3141;
}

/* .btnWrapper {

} */