.container {
    font-size: 14px;
    min-height: 100vh;
    background-color: #000;
}

.videoWrapper {
    margin: 0 auto;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-bottom: 20px; */
}

.btn {
    margin: 0 auto;
    margin-top: 14px;
    display: block;
}

.items {
    margin-top: 16px;
    display: flex;
    justify-content: center;
}

.item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    font-size: 12px;
}